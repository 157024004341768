<template>
  <div class="wrapper">
    <div id="slider" class="container">
      <img @click="scrollLeft" class="left" src="@/assets/icons/arrow-right.png">
      <div v-for="(item,index) in items" :key="index" class="image" :class="{'active': activeIndex == index}" @click="activeIndex = index">
        <img :src="item['head_image']" class="pointer">
      </div>
      <img @click="scrollRight" class="right" src="@/assets/icons/arrow-right.png">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activeIndex: 0
    }
  },
  watch: {
    activeIndex () {
       this.selectImage(this.activeIndex)
    }
  },
  mounted () {
    this.selectImage(this.activeIndex)
  },
  methods: {
    selectImage (index) {
      this.$emit('selectedImage', index)
    },
    scrollLeft () {
      let content = document.querySelector("#slider")
      content.scrollLeft -= 100
    },
    scrollRight () {
      let content = document.querySelector("#slider")
      content.scrollLeft += 100
    }
  }
}
</script>
<style lang="sass" scoped>
.wrapper
  position: relative
  .left, .right
    position: absolute
    z-index: 1
    width: 40px
    top: 0
    bottom: 0
    margin: auto
    cursor: pointer
  .left
    left: 0
    transform: rotate(180deg) translateY(-1px)
  .right
    right: 0
  .container::-webkit-scrollbar
    display: none
  .container
    display: flex
    overflow-x: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    .image
      flex: 0 0 22%
      margin: 10px 15px 0 0
      max-height: 150px
      display: flex
      align-items: center
      justify-content: center
      border: 1px solid $grey-5
      background: map-get($greyShades, 'greyShade1')
      &.active
        border: 1px solid $black
      &:nth-child(4n)
        margin-right: 0
      img
        max-width: 100%
        max-height: 100%
        padding: 10px
</style>
