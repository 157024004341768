<template>
  <div class="image-gallery">
    <figure @click="isZoom = !isZoom" :itemid="items[this.selectImage]['src']" class="image-gallery__fig image-gallery__fig--zoom">
      <a>
        <div>
          <img :src="items[this.selectImage]['head_image']" :itemid="items[this.selectImage]['src']" :alt="items[this.selectImage]['title']" :class="{'isZoom': isZoom}">
        </div>
      </a>
    </figure>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectImage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isZoom: false
    }
  }
}
</script>

<style lang="sass" scoped>
.image-gallery
  a
    display: block
    overflow: hidden
    max-width: 1600px
  img
    max-width: 100%
    transition: transform .2s
    @media screen and (max-width: $phone-breakpoint)
      max-height: 237px
    &.isZoom
      transform: scale(1.5)
  &__fig
    margin-bottom: 0
    &--zoom, &--zoom a
      cursor: zoom-in
</style>
