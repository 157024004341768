<template>
  <div class="not-found">
    <div class="content-left">
      <h2 class="title">
        404
      </h2>
    </div>
    <div class="content-right">
      <h2 class="title">
        The page you are looking for is not available.
      </h2>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: '404'
    }
  }
}
</script>

<style lang="sass" scoped>
.not-found
  height: 60vh
  display: flex
  flex-direction: column
  justify-content: center
  .content-left, .content-right
    h2
      margin-bottom: 20px
</style>