<template>
  <div class="details-wrapper">
    <div v-if="!isLoader">
      <div v-if="productsDetails && Object.keys(productsDetails).length > 0">
        <div class="border m-20"></div>
        <div class="details-block center-wrapper">
          <main class="container">
            <!-- Left Column / Image -->
            <div class="left-column">
              <div class="product-image">
                <imageSlider :items="getGalleryItems(productsDetails.image)" :selectImage="selectImage" />
              </div>
              <div class="gallery-image" v-if="productsDetails.image && productsDetails.image.length > 0">
                <imageGallery :items="getGalleryItems(productsDetails.image)" @selectedImage="selectImage = $event" />
              </div>
            </div>
            <!-- Right Column -->
            <div class="right-column">
              <div class="product-tags">
                <span class="list">Home</span>
                <span class="list" v-if="productsDetails.category && productsDetails.category.name">{{ productsDetails.category.name }}</span>
                <span class="list" v-if="productsDetails.collections && productsDetails.collections.name">{{ productsDetails.collections.name }}</span>
              </div>
              <div class="product-rating">
                <span>
                  <Rating :isShow="true" :isActive="true" />
                </span>
                <span>(1 customer review)</span>
              </div>
              <div class="product-title">
                <h1 v-if="productsDetails['Name']">{{ productsDetails['Name'] }}</h1>
                <div v-if="userDetails && userDetails.id && productsDetails['Price']"  class="product-price">{{ `$${ productsDetails['Price'].toFixed(2) }` }}</div>
              </div>
              <div class="border m-20"></div>
              <!-- Product Description -->
              <div class="product-description">
                <p v-if="productsDetails['CubeFt']">Cube Ft: {{ productsDetails['CubeFt'] }}</p>
              </div>
              <div class="border m-35"></div>
              <div class="information">
                <p v-if="productsDetails['SKU']">SKU: <span>{{ productsDetails['SKU'] }}</span></p>
                <p v-if="productsDetails['category'] && productsDetails['category']['name']">Categories: <span>{{ productsDetails['category']['name'] }}</span></p>
                <p v-if="productsDetails['collections'] && productsDetails['collections']['name']">Collection: <span>{{ productsDetails['collections']['name'] }}</span></p>
                <!-- <p>Share: <span class="share">
                  <img src="@/assets/icons/facebook.svg" class="pointer" />
                  <img src="@/assets/icons/twitter.svg" class="pointer" />
                  <img src="@/assets/icons/linkedin.svg" class="pointer" />
                  <img src="@/assets/icons/pinterest.svg" class="pointer" />
                  </span>
                </p> -->
                <div class="warehouse" v-if="productsDetails['warehouses'] && productsDetails['warehouses'].length > 0">
                  Warehouse:
                  <div>
                    <span v-for="(item, index) in productsDetails['warehouses']" :key="index">{{ `${item['name']}${index !== productsDetails['warehouses'].length - 1 ? ',' : ''}` }}</span>
                  </div>
                </div>
                <p class="product-information" v-if="productsDetails['Description']">
                  Description: 
                  <span v-html="productsDetails['Description']" />
                </p>
                
                <p v-if="productsDetails['Height']">Carton Dimensions: <span>{{ productsDetails['width'] ? productsDetails['width']: 0 }} </span>*<span>{{ productsDetails['Depth'] ? productsDetails['Depth'] : 0 }} </span>*<span>{{ productsDetails['Height'] ? productsDetails['Height'] : 0 }} </span></p>
                
                <p v-if="productsDetails['Short_Description']">Product Dimensions: <span>{{ productsDetails['Short_Description'] }}</span> </p>
               
                <p v-if="productsDetails['Warranty']" class="warranty">
                  Warranty:
                  <span v-html="productsDetails['Warranty']" />
                </p>
                <p v-if="productsDetails['Material']">
                  Material:
                  <span>{{ productsDetails['Material'] }}</span>
                </p>
                <p v-if="productsDetails['Fabric']">
                  Fabric:
                  <span>{{ productsDetails['Fabric'] }}</span>
                </p>
              </div>
            </div>
          </main>
        </div>
        <!-- <div class="product-info">
         <div class="border m-25"></div>
          <div class="center-wrapper">
           <PrdouctExtraInfo :items="productsDetails" />
          </div>
        </div> -->
        <div class="product-list-details">
          <div class="border m-20"></div>
          <div class="center-wrapper">
            <Details @addToCart="collectionToCart(item)" @updateCart="updateCartItems = $event" v-for="(item, index) in collectionProducts" :key="index" :index="index" :items="item" :productId="getID">
              <!-- <ProductAttributes @close="collectionClose" @updateCart="updateCartItems = $event" :details="item" /> -->
            </Details>
          </div>
        </div>
        <div class="product-related">
         <div class="border m-100"></div>
          <div v-if="relatedProducts && relatedProducts.length > 0" class="center-wrapper">
            <div class="related-products">
              <h1 class="label">Related Products</h1>
            </div>
            <!-- static field -->
            <ProductColumn :items="relatedProducts" type="related" />
          </div>
        </div>
      </div>
      <div v-else-if="errorStatus === 404">
        <MH404 />
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import getMediaURL from '@/mixins/getMediaURL'
import MH404 from '@/views/MH404'
import Loader from '@/components/molecules/Loader'
import Rating from '@/components/organisms/Rating'
import imageSlider from '@/components/molecules/imageSlider'
import imageGallery from '@/components/molecules/imageGallery'
import Details from '@/components/molecules/Details'
// import ProductAttributes from '@/components/molecules/ProductAttributes'
// import PrdouctExtraInfo from '@/components/molecules/PrdouctExtraInfo'
import ProductColumn from '@/components/molecules/ProductColumn'
export default {
   mixins: [getMediaURL],
   components: {
      Loader,
      Rating,
      MH404,
      imageSlider,
      imageGallery,
      Details,
      // ProductAttributes,
      // PrdouctExtraInfo,
      ProductColumn
   },
   data () {
      return {
        selectImage: 0,
        collectionProduct_ID: null,
        updateCartItems: {}
      }
   },
   metaInfo() {
      return {
        title: this.productsDetails['PageTitle'] ? this.productsDetails['PageTitle'] : this.productsDetails['Name']
      }
   },
   computed: {
      ...mapGetters('auth', ['userId']),
      ...mapState('auth', ['userDetails']),
      ...mapState(['isLoader', 'errorStatus']),
      ...mapState('products', ['productsDetails', 'collectionProducts', 'relatedProducts', 'wishList']),
      getID() {
        return this.collectionProduct_ID
      },
      getWishList () {
        const data = this.wishList.filter(item => item.users_permissions_user == this.userId && item.product == this.productsDetails.id)
        return data
      },
      isWishList () {
        if (this.getWishList && this.getWishList.length > 0) {
          return true
        }
        return false
      }
   },
  watch: {
    '$route' (to, from) {
      this.initialize()
    },
    productsDetails: function () {
      this.collectionProduct_ID = this.productsDetails.id
      if (this.productsDetails.collections && this.productsDetails.collections.id) {
        this.fetchCollectionProducts({ 'collections.id': this.productsDetails.collections.id })
      }
      if (this.productsDetails.category && this.productsDetails.category.id) {
        this.fetchRelatedProducts({ 'category.id_in': this.productsDetails.category.id, _limit: 4, 'id_nin': this.productsDetails.id })
      }
    }
  },
   created () {
    this.$store.commit('products/setRelatedProduct', [])
     this.initialize()
   },
   methods: {
      initialize () {
        this.$store.dispatch('products/getProductDetails', this.$route.params.id)
      },
      fetchCollectionProducts (query) {
        this.$store.dispatch('products/fetchCollectionProducts', query)
      },
      fetchRelatedProducts (query) {
        this.$store.dispatch('products/fetchRelatedProducts', query)
      },
      collectionToCart (data) {
        let cartData = {}
        if (this.updateCartItems && Object.keys(this.updateCartItems).length > 0) {
          cartData = this.updateCartItems
        } else {
          cartData = {Price: data['Price'].toFixed(2), Quantity: data['Quantity'], id: data['id'], total: data['Price'].toFixed(2)}
        }
        this.addToCart(false, cartData)
      },
      addToCart(isDetails, value) {
        let data = null

        if (isDetails) {
          data = this.productsDetails
          data.total = this.productsDetails.Price
          data.Quantity = 1
        } else {
          data = value
        }
       
        const cartItems = {
          users_permissions_user: this.userId,
          cart_details: [
            {
              price: data.Price,
              quantity: data.Quantity,
              subtotal: data.total,
              CartProduct: [
                {
                  product: 
                  {
                    id: data.id
                  },
                  Price: data.Price,
                  Quantity: data.Quantity,
                }
              ]
            }] 
          }
        this.$store.dispatch('products/addToCart', cartItems)
        window.itemCoutner = window.itemCoutner != null ? window.itemCoutner + 1 : 1
        //this.$store.dispatch('products/updateToCart', cartItems)
        this.updateCartItems = {}
      },
      addWishList() {
        this.$store.dispatch('products/addWishList', {'product': this.productsDetails.id, users_permissions_user: this.userId})
      },
      removeWishList() {
        this.$store.dispatch('products/removeWishList', this.getWishList[0].id)
      },
      collectionClose() {
        this.collectionProduct_ID = null
      }
   }
}
</script>

<style lang="sass" scoped>
%text-props
  @include font-source('Poppins', 13px, 400, $text-grey)
.details-wrapper
   .details-block
     margin-top: 1.5em
   .container
     display: flex
   .left-column
     width: 65%
     margin-right: 5em
     display: flex
     flex-direction: column
     .product-image, .gallery-image
        display: flex
        flex-direction: column
   .right-column
     width: 35%
     .product-tags, .product-rating
        display: flex
        align-items: center
        flex-wrap: wrap
        margin-bottom: 0.62em
        span
           @extend %text-props
           margin: 0 0.31em
           &.list
             position: relative
             margin: 0 10px
             &:last-child
               &:after
                 content: unset
             &:after
                 position: absolute
                 content: '>'
                 right: -15px
     .product-title
        h1
          margin-bottom: 0.62em 
        h1, .product-price
           text-align: left
           @include font-source('Poppins', 40px, 600, $black1)
        .product-price
           font-weight: 400
           font-size: 22px
     .product-description
       margin-bottom: -0.5em
       p
         @extend %text-props
         font-size: 15px
         line-height: 25px
         text-align: start
     .add-to-cart
       margin-bottom: 1.5em
       button
         width: 100%
         height: 60px
         background: $black1
         color: $white
         border: none
         @include font-source('Poppins', 13px, 600)
     .add-to-wishlist, .box
        display: flex
        align-items: center
        .box
            width: 50px
            height: 50px
            border: 2px solid map-get($greyShades, 'greyShade3')
            justify-content: center
            margin-right: 1em 
            img
               width: 40%
        p
           @extend %text-props
           font-size: 15px
           color: $black1
     .information
        display: flex
        flex-direction: column
        align-items: baseline
        p, .share
           display:  flex
        .warehouse
          display: flex
          margin-top: 1.87em
          font-size: 15px
          color: $text-grey
          > div
            display: flex
            align-items: center
            justify-content: center
            flex-wrap: wrap
            span
              &:not(:first-child)
                margin: 0 5px
        .product-information
          flex: 1
          line-height: 25px
          text-align: left
          @include font-source('Poppins', 15px, 400, $text-grey)
        .warranty
          span
            text-align: left
            margin-left: 5px
        p
           @extend %text-props
           font-size: 15px
           margin-top: 1.87em
           &:first-child
              margin-top: 0
           span
             color: $black1
             margin-left: 0.31em 
           .share
              display: flex
              margin-left: 1.1em
              img
                 width: 14px
                 margin-right: 1.1em
.product-list-details
.related-products, .product-related
  margin-bottom: 4em
  h1.label
    @include font-source('Poppins', 35px, 600, $black1)
    position: relative
    &:after
        position: absolute
        width: 8%
        height: 2px
        background: $black1
        content: ''
        bottom: -8px
        left: 0
        right: 0
        margin: auto
@media screen and (max-width: $md-breakpoint)
  .details-wrapper
   .container
     flex-direction: column
     .left-column, .right-column
        width: 100%
        img
          position: unset
</style>
