<template>
  <div v-if="items && Object.keys(items).length > 0" class="detail-section">
    <div class="wrapper">
      <div class="Rtable Rtable--5cols Rtable--collapse">
        <div v-if="index === 0" class="Rtable-row Rtable-row--head">
          <div class="Rtable-cell topic-cell column-heading left">PRODUCT</div>
          <div class="Rtable-cell pdf-cell column-heading">QT. AVAILABLE</div>
          <div class="Rtable-cell pdf-cell column-heading">CUBE FT</div>
          <div class="Rtable-cell pdf-cell column-heading" v-if="userDetails && userDetails.id">UNIT PRICE</div>
          <div class="Rtable-cell pdf-cell column-heading" v-if="userDetails && userDetails.id">QUANTITY</div>
          <div class="Rtable-cell pdf-cell column-heading" v-if="userDetails && userDetails.id">TOTAL PRICE</div>
          <!-- <div class="Rtable-cell topic-cell column-heading">WAREHOUSE</div> -->
       <!--  <div class="Rtable-cell replay-link-cell column-heading">Quantity</div> -->
        <!-- <div class="Rtable-cell pdf-cell column-heading">DEPTH</div> -->
          <!-- <div class="Rtable-cell pdf-cell column-heading">HEIGHT</div>  -->
        <!-- <div class="Rtable-cell pdf-cell column-heading">CUBES</div> -->
          <div class="Rtable-cell pdf-cell column-heading" v-if="userType !== salesRep"></div>
        </div>
        <div class="Rtable-row">
          <div @click="onDetails(items)" class="pointer Rtable-cell topic-cell">
           <div class="Rtable-cell--heading">PRODUCT</div>
            <div class="Rtable-cell--content title-content">
              <div class="item">
                <div class="image">
                  <img v-if="items.image && items.image[0]" :src="getMediaURL(items.image[0], 'small')" />  
                </div>
                <div class="name">
                  <span v-if="items['Name']">{{ items['Name'] }}</span>
                  <span v-if="items['SKU']">SKU: {{ items['SKU'] }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="Rtable-cell pdf-cell">
          <div class="Rtable-cell--heading">QT. AVAILABLE</div>
            <div class="Rtable-cell--content date-content"><span class="webinar-date">{{ items['Quantity'] ? items['Quantity'] : 0 }}</span></div>
          </div>
          <div class="Rtable-cell pdf-cell">
          <div class="Rtable-cell--heading">CUBE FT</div>
            <div class="Rtable-cell--content date-content"><span class="webinar-date">{{ items['CubeFt'] ? items['CubeFt'] : 0 }}</span></div>
          </div>
          <div class="Rtable-cell pdf-cell" v-if="userDetails && userDetails.id">
          <div class="Rtable-cell--heading">UNIT PRICE</div>
            <div class="Rtable-cell--content date-content"><span class="webinar-date">${{ items['Price'] ? items['Price'].toFixed(2) : 0 }}</span></div>
          </div>
          <div class="Rtable-cell pdf-cell" v-if="userDetails && userDetails.id">
          <div class="Rtable-cell--heading">QUANTITY</div>
            <div class="Rtable-cell--content date-content">
            <div class="quantity">
                  <span @click="addProduct(items['Quantity'])" class="pointer">+</span>
                  <span>{{ quantity }}</span>
                  <span @click="removeProduct" class="pointer">-</span>
                </div>
            </div>
          </div>
          <div class="Rtable-cell pdf-cell" v-if="userDetails && userDetails.id">
          <div class="Rtable-cell--heading">TOTAL PRICE</div>
            <div class="Rtable-cell--content date-content"><span class="webinar-date">${{ getTotal.toFixed(2) }}</span></div>
          </div>
          <!-- <div class="Rtable-cell topic-cell">
          <div class="Rtable-cell--heading">WAREHOUSE</div>
            <div class="Rtable-cell--content access-link-content warehouse">
              <span v-for="(item, subindex) in items['warehouses']" :key="subindex">
              {{ `${item['name']}${subindex !== items['warehouses'].length - 1 ? ',' : ''}` }}
              </span>
            </div>
          </div> -->
           <!-- <div class="Rtable-cell replay-link-cell">
          <div class="Rtable-cell--heading">Quantity</div>
             <div data-label="QUANTITY" class="col">
              <div class="quantity">
                <span class="pointer" @click="addProduct(items['Price'])">+</span>
                <span v-if="items['Quantity']">{{ items['Quantity'] }}</span>
                <span class="pointer" @click="removeProduct(items['Price'])">-</span>
              </div>
             </div>
           </div> -->
          <!-- <div class="Rtable-cell Rtable-cell--foot pdf-cell">
           <div class="Rtable-cell--heading">DEPTH</div>
             <div class="Rtable-cell--content pdf-content"><span>{{ items['Depth'] }}</span></div>
           </div> -->
           <!-- <div class="Rtable-cell Rtable-cell--foot pdf-cell">
           <div class="Rtable-cell--heading">HEIGHT</div>
             <div class="Rtable-cell--content pdf-content"><span>{{ items['Height'] ? items['Height'] : 'Height'  }}</span></div>
          </div> -->
         <!-- <div class="Rtable-cell Rtable-cell--foot pdf-cell">
          <div class="Rtable-cell--heading">CUBES</div>
            <div class="Rtable-cell--content pdf-content"><span>{{ items['Cubes'] ? items['Cubes'] : 'Cubes' }}</span></div>
          </div>-->
          <div v-if="userType !== salesRep" class="Rtable-cell Rtable-cell--foot pdf-cell">
            <div class="Rtable-cell--content pdf-content">
              <button v-if="userDetails && userDetails.id" class="pointer" @click="addToCart()">ADD TO CART</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Add comments -->
      <slot v-if="productId === items.id"/>
    </div>
  </div>
</template>

<script>
import getMediaURL from '@/mixins/getMediaURL'
import { mapState } from 'vuex'
export default {
  mixins: [getMediaURL],
  props: {
    items: [Object],
    index: [Number, String],
    productId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      quantity: 1
    }
  },
  watch: {
    quantity() {
      this.$emit('updateCart', {Price: this.items['Price'].toFixed(2), Quantity: this.quantity, id: this.items.id, total: this.getTotal})
    }
   
  },
  computed: {
    ...mapState('auth', ['userDetails', 'user', 'userType', 'salesRep']),
    getTotal () {
      return this.items['Price'].toFixed(2) * this.quantity
    }
  },
  methods: {
    onDetails(item) {
      this.$router.push({ name: 'ProductDetails', params: {id: item.id} })
    },
    addProduct (qty) {
      if (this.quantity >= 1 && qty > this.quantity) {
        this.quantity++
      }else{
        this.$store.commit('products/setNotification', { type: 'cart', method: 'warn', message: 'Quantity should be less then available Qty!'})
      }
    },
    removeProduct () {
      if (this.quantity > 1) {
        this.quantity--
      }
    },
    addToCart() {
      this.$emit('updateCart', {Price: this.items['Price'].toFixed(2), Quantity: this.quantity, id: this.items.id, total: this.getTotal})
      this.$emit('addToCart')
    }
  }
}
</script>

<style lang="sass" scoped>
%text-props
  @include font-source('Poppins', 15px, 600, $black1)
.detail-section
  display: flex
  flex-direction: column
.wrapper
  width: 100%
  border-left: 1px solid map-get($greyShades, 'greyShade4')
  border-right: 1px solid map-get($greyShades, 'greyShade4')
.date-cell
  width: 20%
.topic-cell
  width: 41%
.pdf-cell, .replay-link-cell, .access-link-cell 
  width: 13%
.Rtable
  display: flex
  flex-wrap: wrap
  padding: 0
  .Rtable-row
    width: 100%
    display: flex
    padding: 12px 16px
    align-items: center
    text-decoration: none
    border-bottom: 1px solid map-get($greyShades, 'greyShade4')
    &--head
      display: flex
      padding: 0.7em 1em
      min-height: 3.1em
      align-items: center
      border-bottom: 1px solid map-get($greyShades, 'greyShade4')
      border-top: 1px solid map-get($greyShades, 'greyShade4')
      .left
        text-align: left
    .Rtable-cell
      box-sizing: border-box
      flex-grow: 1
      &.column-heading
        @extend %text-props

      .Rtable-cell--heading
        display: none
      .Rtable-cell--content
        .quantity
          display: flex
          align-items: center
          justify-content: space-around
          border: 1px solid map-get($greyShades, 'greyShade4')
          padding: 0 0.5em
          span
            padding: 0.5em
            &:first-child, &:nth-child(3)
              @include font-source('Poppins', 16px, 400, $text-grey)
        span
          @extend %text-props
        .item
          display: flex
          align-items: center
          @media screen and (max-width: $md-breakpoint)
            flex-direction: column
            align-items: flex-end
          .image
            background: map-get($greyShades, 'greyShade1')
            width: 90px
            height: 80px
            margin-right: 15px
            display: flex
            align-items: center
            @media screen and (max-width: $md-breakpoint)
              margin-right: 0
            img
              max-width: 100%
              padding: 10px
          .name
            display: flex
            flex-direction: column
            span
              @include font-source('Poppins', 14px, 400, $text-grey)
              &:nth-child(2)
                color: $black1
                font-size: 13px
                font-weight: 600
        &.warehouse
          span
            font-size: 13px
            color: $text-grey
            @media screen and (max-width: $md-breakpoint)
              text-align: end
        button
          background: $black1
          color: $white
          padding: 10px 8px
          font-size: 11px
          outline: none
          border: none
@media all and (max-width: $ms-breakpoint)
  .Rtable--collapse
    .Rtable-row 
      display: flex
      flex-wrap: wrap
      border-top: 1px solid map-get($greyShades, 'greyShade1')
      .Rtable-cell
        width: 100% !important
        display: flex
        align-items: center
        margin-bottom: 1em
        .Rtable-cell--heading
          display: inline-block
          flex: 1
          max-width: 120px
          min-width: 120px
          border-right: 1px solid map-get($greyShades, 'greyShade1')
          margin-right: 1em
          text-align: left
          @include font-source('Poppins', 15px, 600, $black1)
        .Rtable-cell--content 
          flex: 2
          padding-left: 1em
      .Rtable-cell--content
        display: flex
        justify-content: flex-end
    .Rtable-row--head
      display: none
.no-flexbox .Rtable
  display: block
  &.Rtable-cell
    width: 100%
</style>
